// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import * as bootstrap from "bootstrap";

import Splide from "@splidejs/splide";

window.addEventListener("turbo:load", () => {
  if (document.querySelector(".splide#variety")) {
    const splide = new Splide(".splide", {
      type: "loop",
      pagination: false,
      perPage: 6,
      gap: 0,
      padding: 0,
      rewind: false,
      autoWidth: true,
    });
    splide.mount();
  } else if (document.querySelector(".splide")) {
    const splide = new Splide(".splide", { type: "loop" });
    splide.mount();
  }
});

window.addEventListener("turbo:frame-load", () => {
  if (window.location.pathname === "/varieties") {
    initializeInfiniteScroll();
  }
});

getParameter = (key) => {
  address = window.location.search;

  parameterList = new URLSearchParams(address);

  return parameterList.get(key);
};

function initializeInfiniteScroll() {
  let InfiniteScroll = require("infinite-scroll");
  let elemCard = document.querySelector("#plants-frame");

  if (
    elemCard !== null &&
    document.querySelector(".pagination a[rel=next]") !== null
  ) {
    var infScroll = new InfiniteScroll(elemCard, {
      // options
      path: function () {
        let pageNumber = this.loadCount + 2;
        return `/fetch-varieties/${pageNumber}${window.location.search}`;
      },
      append: ".col-md-3.variety",
      history: false,
      debug: false,
      prefill: false,
    });
  }
}
